<template>
  <fcs-container class="scrollablePage">
    <div class="container">
      <div style ="margin: 1rem 0rem;">
      <h2 class="mt-1" style="color: #002F6C; font-family: FordAntennaCondLight;"> {{ $t('inhibit.addRemoveVehiclesFor') }} {{ this.$route.params.scheduleName }}</h2>
      </div>
      <InhibitVehiclePaginatedTable ref="vehicleTable"
                                    :should-reset-selected-vins=false
                                    :should-run-vehicles-refresh=false />
      <div style="display: flex; justify-content: flex-end;">
        <fcs-button
          variant="outline-primary"
          style="margin-right: 0.5rem;"
          data-qa="cancelEditButton"
          @click="cancelUpdate()">
          {{ $t('global.cancel') }}
        </fcs-button>
        <fcs-button
          variant="primary"
          style="margin-left: 0.5rem;"
          @click="checkForOverlapAndUpdate()"
          data-qa="saveEditButton">
          {{ $t('global.save') }}
        </fcs-button>
      </div>
      <fcs-customizable-dialog
        :dialogTitle="$t('inhibit.confirmationDialogTitle')"
        ref="inhibitOverlapWarningModal"
        data-qa="inhibitOverlapWarningModal"
        :submitDisabled="false"
        :submitButtonText="$t('global.submit')"
        :cancelButtonText="$t('global.cancel')"
        submitButtonVisible
        cancelButtonVisible
        appendToBody
        :cancel-button-handler="cancelWarningModal"
        :submit-button-handler="closeWarningModalOnSubmit"
        submitButtonType="primary">
        <p>{{this.vinsWithOverlappingInhibitSchedules}} {{$t('inhibit.overlappingInhibitWarning')}}</p>
      </fcs-customizable-dialog>
    </div>
  </fcs-container>
</template>

<script>
import InhibitVehiclePaginatedTable from '@/fleetstartinhibit/components/InhibitVehiclePaginatedTable';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { i18n } from '@/i18n';

export default {
  name: 'EditVehicles',
  components: { InhibitVehiclePaginatedTable },
  data () {
    return {
      vinsWithOverlappingInhibitSchedules: 0,
      isWarningModalVisible: false,
      currentSchedule: null
    };
  },
  async mounted () {
    if (!this.getEditSchedule) {
      if (!this.getExistingSchedules || this.getExistingSchedules.length === 0) {
        await this.getAllExistingSchedules();
      }

      for (const schedule of this.getExistingSchedules) {
        if (schedule.scheduleName === this.$route.params.scheduleName) {
          await this.editSchedule({ schedule });
          this.currentSchedule = schedule;
          return;
        }
      }
    }

    await this.$router.push({ name: 'Inhibit' });
  },
  computed: {
    ...mapGetters('vehicles', [
      'getSelected',
      'getRequestId',
      'getSelectedVins',
      'getEditSchedule',
      'getExistingSchedules'
    ])
  },
  methods: {
    ...mapActions('vehicles', [
      'getAllExistingSchedules',
      'updateCurrentSchedule',
      'deleteScheduledRequest',
      'removeVehicleInhibit',
      'isScheduleActive',
      'editSchedule'
    ]),
    ...mapMutations('vehicles', [
      'SET_REQUEST_ID',
      'SET_SELECTED_VINS'
    ]),
    showWarningModal () {
      this.isWarningModalVisible = true;
      this.$refs.inhibitOverlapWarningModal.showModal();
    },
    cancelWarningModal () {
      this.isWarningModalVisible = false;
      this.$refs.inhibitOverlapWarningModal.hideModal();
      this.cancelUpdate();
    },
    async closeWarningModalOnSubmit () {
      this.isWarningModalVisible = false;
      this.$refs.inhibitOverlapWarningModal.hideModal();
      await this.updateVehicles();
    },
    async checkForOverlapAndUpdate () {
      const request = {
        ...this.currentSchedule,
        requestId: this.getRequestId,
        vins: this.getSelectedVins
      };

      this.vinsWithOverlappingInhibitSchedules = await this.$store.dispatch('vehicles/validateScheduleByID', request);
      if (this.vinsWithOverlappingInhibitSchedules > 0) {
        this.showWarningModal();
      } else {
        this.updateVehicles();
      }
    },
    async updateVehicles () {
      let response;
      let actionType = '';
      if (this.getSelectedVins === undefined || this.getSelectedVins.length === 0) {
        const activeSchedule = await this.isScheduleActive(this.getRequestId);
        if (activeSchedule) {
          await this.removeVehicleInhibit({ vins: Object.keys(this.getSelected) });
        }
        response = await this.deleteScheduledRequest({ requestId: this.getRequestId });
      } else {
        actionType = 'UPDATE';
        response = await this.updateCurrentSchedule(
          {
            requestId: this.getRequestId,
            vins: this.getSelectedVins
          }
        );
      }
      const status = await this.setStatusMessage(response, actionType);
      await this.$router.push(
        {
          name: 'Inhibit',
          params: {
            responseStatus: response,
            message: status.message,
            responseStyle: status.responseStyle
          }
        }
      );
    },
    async setStatusMessage (response, actionType) {
      let responseStyle = '';
      let message = '';
      if (response >= 200 && response < 300) {
        responseStyle = 'fcs-alert-success';
        if (actionType === 'UPDATE') {
          message = `${i18n.t('inhibit.editVehiclesSuccessMessage')}`;
        } else {
          message = `${i18n.t('inhibit.deleteSchedule.deleteSuccessMessage')}`;
        }
      } else {
        responseStyle = 'fcs-alert-danger';
        if (actionType === 'UPDATE') {
          message = `${i18n.t('inhibit.editVehiclesErrorMessage')}`;
        } else {
          message = `${i18n.t('inhibit.deleteSchedule.deleteErrorMessage')}`;
        }
      }
      return { message: message, responseStyle: responseStyle };
    },
    async cancelUpdate () {
      await this.$router.push('/inhibit');
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.scrollablePage {
  overflow-y: scroll;
}

</style>
